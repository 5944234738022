<style lang="scss" scoped>
.pacemaker-main-content::-webkit-scrollbar {
  width: 12px;
}
.pacemaker-main-content::-webkit-scrollbar-track {
  border-radius: 10px;
}
.pacemaker-main-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.pacemaker-main-content::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}
.pacemaker-main-content {
  flex: 1;
  overflow: auto;
  padding: 0 10px 20px;
  background: #eff0f7;

  .button-panel {
    width: 100%;
    bottom: 0;
    margin: 20px 0 20px 0;
    display: flex;
    justify-content: space-around;
  }
  .pacemaker-basic-information {
    // border-style: solid;
    // border-width: medium;
    // border-color: #f2f6fc;
    margin-top: 10px;
    .pacemaker-basic-information-body {
      .pacemaker-radios {
        padding-left: 10px;
        display: flex;
        margin-bottom: 5px;

        .pacemaker-change-reason {
          .input-form {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
          }

          .input-form-item-row {
            width: 100%;
            margin: 0 0.5rem;
            display: flex;
            justify-content: flex-start;
          }

          .input-form-item {
            min-width: 28%;
            margin: 0.4rem 1.5rem;
            display: flex;
            align-items: center;

            .input_has_unit {
              margin: 0;
            }
          }
          @media screen and (max-width: 1180px) {
            .input-form-item {
              min-width: 40%;
            }
          }

          .input-form-item-label {
            width: 120px;
          }
        }
      }

      .add-pacemaker {
        display: flex;
        margin: 10px 0;

        .el-card {
          /* margin: 0 10px; */
          margin-right: 10px;
        }

        .el-card:last-child {
          margin: 0;
        }

        .input-form-card {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .input-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .input-form-item {
          min-width: 28%;
          margin: 0.4rem 1.5rem;
          display: flex;
          align-items: center;

          .input_has_unit {
            width: 200px;
            margin: 0;
          }

          .input_has_unit_text {
            margin: 0;
            width: 280px;
          }

          .el-date-editor.el-input {
            width: 200px;
          }
        }

        .input-form-item {
          min-width: 30%;
          margin: 0.4rem 0 0.4rem 0.5rem;
          display: flex;
          align-items: center;
        }

        @media screen and (max-width: 1180px) {
          .input-form-item {
            min-width: 40%;
          }
        }

        .input-form-item-label {
          width: 120px;
        }
      }

      .pacemaker-change {
        margin-top: 20px;
        margin-bottom: 40px;
        border-style: solid;
        border-width: medium;
        /* border-color: #c0c4cc; */
        border-color: #ecf0f1;
        padding: 15px 10px 15px 10px;
        border-radius: 5px;
        position: relative;
        background-color: #fff !important;
        box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);

        .input-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .input-form-item-row {
          width: 100%;
          margin: 0 0.5rem;
          display: flex;
          justify-content: flex-start;
        }

        .input-form-item {
          min-width: 20%;
          margin: 0.4rem 1.5rem;
          display: flex;
          align-items: center;
        }
        @media screen and (max-width: 1180px) {
          .input-form-item {
            min-width: 40%;
          }
        }

        .input-form-item-label {
          width: 120px;
        }

        .pacemaker-change-record {
          .pacemaker-change-record-head {
            .inner-title {
              position: absolute;
              top: -12px;
              left: 12px;
              background: #fff;
              padding: 0 10px;
              text-align: center;
              font-size: 16px;
              font-weight: bold;
              padding-top: 5px;
            }
          }

          .pacemaker-change-record-body {
            .input-form {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              margin: 0 0 1.5rem 0;
            }

            .input-form-item-row {
              width: 100%;
              margin: 0 0.5rem;
              display: flex;
              justify-content: flex-start;
            }

            .input-form-item {
              min-width: 20%;
              margin: 0.4rem 1.5rem 0.5rem 1rem;
              display: flex;
              align-items: center;
            }
            @media screen and (max-width: 1180px) {
              .input-form-item {
                min-width: 40%;
              }
            }

            .input-form-item-label {
              width: 120px;
              word-break: break-all;
              width: 7em;
              vertical-align: middle;
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }

  .temporary-pacing-electrodes {
    border-style: solid;
    border-width: medium;
    border-color: #ebeef5;
    margin-top: 30px;
    margin-bottom: 30px;

    .temporary-pacing-electrodes-content {
      .temporary-pacing-electrodes-content-head {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
      }

      .temporary-pacing-electrodes-content-body {
        .input-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .input-form-item-row {
          width: 100%;
          margin: 0 0.5rem;
          display: flex;
          justify-content: flex-start;
        }

        .input-form-item {
          min-width: 20%;
          margin: 0.4rem 1.5rem;
          display: flex;
          align-items: center;
        }
        @media screen and (max-width: 1180px) {
          .input-form-item {
            min-width: 40%;
          }
        }
        .input-form-item-label {
          width: 120px;
        }
      }
    }
  }

  .CRT {
    margin-top: 20px;
    margin-bottom: 40px;
    border-style: solid;
    border-width: medium;
    /* border-color: #c0c4cc; */
    border-color: #ecf0f1;
    padding: 15px 10px 5px 10px;
    border-radius: 5px;
    position: relative;
    background-color: #fff !important;
    box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
    .CRT-content {
      .CRT-head {
        .inner-title {
          position: absolute;
          top: -12px;
          left: 12px;
          background: #fff;
          padding: 0 10px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          padding-top: 5px;
        }
      }

      .CRT-body {
        margin-right: 14px;
        .input-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .input-form-item-row {
          width: 100%;
          margin: 0 0.5rem;
          display: flex;
          justify-content: flex-start;
        }

        .input-form-item {
          min-width: 20%;
          margin: 0.4rem 1.5rem;
          display: flex;
          align-items: center;
        }
        @media screen and (max-width: 1180px) {
          .input-form-item {
            min-width: 40%;
          }
        }

        .input-form-item-label {
          width: 120px;
        }
      }
    }
  }

  .ICD {
    // border-style: solid;
    // border-width: medium;
    // border-color: #dcdfe6;

    margin-top: 30px;
    margin-bottom: 30px;
    border-style: solid;
    border-width: medium;
    border-color: #c0c4cc;
    .ICD-content {
      .ICD-head {
        .inner-title {
          position: relative;
          top: -12px;
          left: 12px;
          background: #fff;
          padding: 0 10px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .ICD-body {
        .input-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .input-form-item-row {
          width: 100%;
          margin: 0 0.5rem;
          display: flex;
          justify-content: flex-start;
        }

        .input-form-item {
          min-width: 20%;
          margin: 0.4rem 1.5rem 1rem 0;
          display: flex;
          align-items: center;
        }
        @media screen and (max-width: 1180px) {
          .input-form-item {
            min-width: 40%;
          }
        }

        .input-form-item-label {
          width: 120px;
        }
      }
    }
  }

  .electrode-lead-implantation {
    margin-top: 20px;
    margin-bottom: 40px;
    border-style: solid;
    border-width: medium;
    /* border-color: #c0c4cc; */
    border-color: #ecf0f1;
    padding: 20px 10px 15px 10px;
    border-radius: 5px;
    position: relative;
    background-color: #fff !important;
    box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);

    // border-top: solid #c0c4cc;
    // border-bottom: solid #c0c4cc;
    .electrode-lead-implantation-head {
      .inner-title {
        position: absolute;
        top: -12px;
        left: 12px;
        background: #fff;
        padding: 0 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-top: 5px;
        display: flex;
        align-items: center;
      }
    }

    .electrode-lead-implantation-body {
      .input-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 1.5rem;
      }

      .input-form-item-row {
        width: 100%;
        margin: 0 0.5rem;
        display: flex;
        justify-content: flex-start;
      }

      .input-form-item {
        min-width: 20%;
        margin: 0.4rem 1.5rem 1rem 0;
        display: flex;
        align-items: center;

        .input_has_unit {
          margin: 0;
        }
      }
      @media screen and (max-width: 1180px) {
        .input-form-item {
          min-width: 40%;
        }
      }

      .input-form-item-label {
        width: 120px;
      }
    }
  }
}
.save-button {
  padding-left: 10px;
  margin-right: 0.5rem;
}
</style>

<template>
  <div class="main-content" v-loading="loading">
    <div class="crt-content">
      <div class="save-button">
        <el-button
          type="success"
          size="mini"
          class="commonBtn"
          @click="open_save"
          v-show="!isReadOnly"
          :disabled="isReadOnly"
        >
          保存
        </el-button>
      </div>

      <el-button @click="isReadOnly = !isReadOnly" size="mini" class="commonBtn" type="primary">
        {{ isReadOnly === true ? '编辑' : '取消' }}
      </el-button>
      <el-button
        icon="el-icon-s-platform"
        @click="infoPreview"
        size="mini"
        class="commonBtn"
        type="primary"
      >
        报告预览
      </el-button>
      <el-button
        icon="el-icon-edit"
        @click="reportSign"
        size="mini"
        class="commonBtn"
        type="success"
      >
        {{ signStatus ? '解签' : '报告签名' }}
      </el-button>
    </div>
    <div class="pacemaker-main-content">
      <!-- 基本信息 -->
      <div class="pacemaker-basic-information">
        <div class="pacemaker-basic-information-body">
          <div class="pacemaker-radios">
            <cqt-radio
              :value="pacemakerInfo.operateType"
              :isReadonly="isReadOnly"
              @update:value="turnChangePacemakerOff"
            >
              <el-radio label="新增起搏器">新增起搏器</el-radio>
              <el-radio label="更换起搏器">更换起搏器</el-radio>
            </cqt-radio>
          </div>

          <div class="add-pacemaker">
            <!-- 起搏器1 -->
            <el-card style="width: 25%" :body-style="{ padding: '20px' }">
              <div class="input-form-card">
                <div class="input-form-item" v-show="isShow">
                  <div class="input-form-item-label">更换原因</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_ReplaceReason"
                    :value.sync="pacemakerInfo.replaceReason"
                  />
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label">起搏器类型</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_Type"
                    :value.sync="pacemakerInfo.pacemakerType"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">囊袋位置</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_PouchLoc"
                    :value.sync="pacemakerInfo.pouchLoc"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">囊袋大小</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_PouchSize"
                    :value.sync="pacemakerInfo.pouchSize"
                  />
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label">切口长度</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pacemakerInfo.incisionLength"
                      size="mini"
                      unit="cm"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">导丝数量</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_WireCount"
                    :value.sync="pacemakerInfo.guideWireNumber"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">可撕开鞘大小</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_CanTearSheathSize"
                    :value.sync="pacemakerInfo.canTearSheathSize"
                  />
                </div>
                <!--
                <div class="input-form-item">
                  <div class="input-form-item-label">临时起搏电极</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_TempElecTest"
                    :value.sync="universalStudy.temporaryPacing"
                  />
                </div> -->
              </div>
            </el-card>
            <!-- 起搏器2 -->
            <el-card style="width: 25%" :body-style="{ padding: '20px' }">
              <div class="input-form-card">
                <div class="input-form-item">
                  <div class="input-form-item-label">远程遥测</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_RemoteTest"
                    :value.sync="pacemakerInfo.remoteTest"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">频率应答</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_FrequencyRespone"
                    :value.sync="pacemakerInfo.frequencyRespone"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">术后不适</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_Discomfort"
                    :value.sync="pacemakerInfo.discomfort"
                  />
                </div>

                <!-- <div class="input-form-item">
                  <div class="input-form-item-label">制动部位</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_BrakeParts"
                    :value.sync="pacemakerInfo.brakeParts"
                  />
                </div> -->
              </div>
            </el-card>
            <!-- 血管 -->
            <el-card style="width: 25%" :body-style="{ padding: '20px' }">
              <div class="input-form-card">
                <div class="input-form-item">
                  <div class="input-form-item-label">血管入路</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_Puncture"
                    :value.sync="universalStudy.vascularApproach"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">入路方式</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/AO_road_method"
                    :value.sync="universalStudy.approachType"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">麻醉方式</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/AO_anesthesia"
                    :value.sync="universalStudy.anesthesiaType"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">造影剂</div>
                  <div>
                    <cqt-input
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      @input="getcontrastAgent"
                      v-model="universalStudy.contrastAgent"
                      size="mini"
                      unit="ml"
                      min="0"
                      max="300"
                      :isReadonly="isReadOnly"
                    >
                      <template slot="append">ml</template>
                    </cqt-input>
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">射线量</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      @input="getrayQuantity"
                      v-model="universalStudy.rayQuantity"
                      size="mini"
                      unit="mGy"
                      min="0"
                      max="500"
                    >
                      <template slot="append">mGy</template>
                    </cqt-input>
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">曝光时间</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="universalStudy.exposureTime"
                      size="mini"
                      unit="min"
                      min="0"
                    >
                      <template slot="append">min</template>
                    </cqt-input>
                  </div>
                </div>
              </div>
            </el-card>
            <!-- 出血 -->
            <el-card style="width: 25%" :body-style="{ padding: '20px' }">
              <div class="input-form-card">
                <div class="input-form-item">
                  <div class="input-form-item-label">出血量</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      @input="getoutTransAmount"
                      v-model="universalStudy.outTransAmount"
                      size="mini"
                      unit="ml"
                      min="0"
                      max="5000"
                    >
                      <template slot="append">ml</template>
                    </cqt-input>
                  </div>
                </div>

                <!-- 输血 -->
                <Transfusion
                  :isReadonly="isReadOnly"
                  ref="Transfusion"
                  :apiOptions="apiOptions"
                  :surgeryInfo="universalStudy"
                />

                <div class="input-form-item">
                  <div class="input-form-item-label">鱼精蛋白</div>
                  <cqt-radio :value.sync="universalStudy.protamine" :isReadonly="isReadOnly">
                    <el-radio label="有">有</el-radio>
                    <el-radio label="无">无</el-radio>
                  </cqt-radio>
                </div>
                <div
                  class="input-form-item"
                  v-show="universalStudy.protamine && universalStudy.protamine !== '无'"
                >
                  <div class="input-form-item-label">鱼精蛋白剂量</div>
                  <div>
                    <cqt-input
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      @input="getprotamineDose"
                      v-model="universalStudy.protamineDose"
                      size="mini"
                      unit="ml"
                      min="0"
                      max="500"
                      :isReadonly="isReadOnly"
                    ></cqt-input>
                  </div>
                </div>

                <!-- 血管闭合方式 -->
                <BloodCloseType
                  :isReadonly="isReadOnly"
                  ref="BloodCloseType"
                  :apiOptions="apiOptions"
                  :surgeryInfo="universalStudy"
                />

                <div class="input-form-item">
                  <div class="input-form-item-label">术后制动</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_BrakAreaTime"
                    :value.sync="universalStudy.postOperativeBraking"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">特殊操作</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_Intervention"
                    :value.sync="universalStudy.intervention"
                  />
                </div>
              </div>
            </el-card>
          </div>

          <!-- 更换起搏器 -->
          <div class="pacemaker-change" v-show="isShow">
            <div class="pacemaker-change-record">
              <div class="pacemaker-change-record-head">
                <span class="inner-title">更换记录</span>
                <!-- <el-tag type="info"
                      style="font-size: 14px; color: #000">更换记录</el-tag> -->
              </div>
              <div class="pacemaker-change-record-body">
                <div class="input-form" style="margin: 0">
                  <div class="input-form-item">
                    <div class="input-form-item-label">原切口位置</div>
                    <cqt-select
                      :isReadonly="isReadOnly"
                      activeUrl="/v1/webconsole/comboData/get/PM_IncisionSite"
                      :value.sync="PMReplaceRecordInfo.incisionSite"
                    />
                  </div>

                  <div class="input-form-item">
                    <div class="input-form-item-label">原切口长度</div>
                    <div>
                      <cqt-input
                        :isReadonly="isReadOnly"
                        class="input_has_unit"
                        placeholder="请输入"
                        type="number"
                        v-model="PMReplaceRecordInfo.incisionLength"
                        size="mini"
                        unit="cm"
                      />
                    </div>
                  </div>
                </div>
                <el-tabs type="border-card">
                  <el-tab-pane label="原右房电极L1">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">原电极功能</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L1PacemakerFunc"
                          :value.sync="PMReplaceRecordInfo.pacemakerFuncL1"
                        />
                      </div>
                      <div class="input-form-item">
                        <div class="input-form-item-label">原右房电极处置</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_RightAtriumL1"
                          :value.sync="PMReplaceRecordInfo.rightAtriumL1"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">起搏功能异常</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L1Abnormality"
                          :value.sync="PMReplaceRecordInfo.abnormalityL1"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">原电极放置问题</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L1PlaceProblem"
                          :value.sync="PMReplaceRecordInfo.placeProblemL1"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">导线完整性异常</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L1WireComplete"
                          :value.sync="PMReplaceRecordInfo.wireCompleteL1"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">除颤异常</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L1DefibriAbnorm"
                          :value.sync="PMReplaceRecordInfo.defibriAbnormalityL1"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">起搏阈值</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.pacingThresholdValueL1"
                            size="mini"
                            unit="V"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">脉宽</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.pulseWidthL1"
                            size="mini"
                            unit="ms"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">感知</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.perceptionValueL1"
                            size="mini"
                            unit="mV"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">阻抗</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.impedanceL1"
                            size="mini"
                            unit="Ω"
                          />
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="原右室电极L2">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">原电极功能</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L2PacemakerFunc"
                          :value.sync="PMReplaceRecordInfo.pacemakerFuncL2"
                        />
                      </div>
                      <div class="input-form-item">
                        <div class="input-form-item-label">原右室电极处置</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_RightVentricleL2"
                          :value.sync="PMReplaceRecordInfo.rightVentricleL2"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">起搏功能异常</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L2Abnormality"
                          :value.sync="PMReplaceRecordInfo.abnormalityL2"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">原电极放置问题</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L2PlaceProblem"
                          :value.sync="PMReplaceRecordInfo.placeProblemL2"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">导线完整性异常</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L2WireComplete"
                          :value.sync="PMReplaceRecordInfo.wireCompleteL2"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">除颤异常</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L2DefibriAbnorm"
                          :value.sync="PMReplaceRecordInfo.defibriAbnormalityL2"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">起搏阈值</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.pacingThresholdValueL2"
                            size="mini"
                            unit="V"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">脉宽</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.pulseWidthL2"
                            size="mini"
                            unit="ms"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">感知</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.perceptionValueL2"
                            size="mini"
                            unit="mV"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">阻抗</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.impedanceL2"
                            size="mini"
                            unit="Ω"
                          />
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>

                  <el-tab-pane label="原左室电极L3">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">原电极功能</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L3PacemakerFunc"
                          :value.sync="PMReplaceRecordInfo.pacemakerFuncL3"
                        />
                      </div>
                      <div class="input-form-item">
                        <div class="input-form-item-label">原左室电极处置</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_LeftVentricleL3"
                          :value.sync="PMReplaceRecordInfo.leftVentricleL3"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">起搏功能异常</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L3Abnormality"
                          :value.sync="PMReplaceRecordInfo.abnormalityL3"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">原电极放置问题</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L3PlaceProblem"
                          :value.sync="PMReplaceRecordInfo.placeProblemL3"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">导线完整性异常</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/PM_L3WireComplete"
                          :value.sync="PMReplaceRecordInfo.wireCompleteL3"
                        />
                      </div>
                      <div class="input-form-item">
                        <div class="input-form-item-label">起搏阈值</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.pacingThresholdValueL3"
                            size="mini"
                            unit="V"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">脉宽</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.pulseWidthL3"
                            size="mini"
                            unit="ms"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">感知</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.perceptionValueL3"
                            size="mini"
                            unit="mV"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">阻抗</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="number"
                            v-model="PMReplaceRecordInfo.impedanceL3"
                            size="mini"
                            unit="Ω"
                          />
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 临时起搏电极 -->
      <div class="electrode-lead-implantation temporary-pacing-electrodes">
        <div class="electrode-lead-implantation-head">
          <span class="inner-title">
            <span style="margin-right: 10px">临时起搏电极</span>
            <cqt-select
              :isReadonly="isReadOnly"
              activeUrl="/v1/webconsole/comboData/get/PM_TempElecTest"
              :value.sync="universalStudy.temporaryPacing"
            />
          </span>
        </div>
        <div class="temporary-pacing-electrodes-content">
          <div v-show="tempPaceEleShow" class="temporary-pacing-electrodes-content-body">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">起搏阈值：</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="universalStudy.pacingThreshold"
                    size="mini"
                    unit="V"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">起搏频率：</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="universalStudy.pacingFrequency"
                    size="mini"
                    unit="Hz"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">起搏输出电压：</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="universalStudy.pacingOutput"
                    size="mini"
                    unit="V"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">感知灵敏度：</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="universalStudy.pacingPerception"
                    size="mini"
                    unit="mV"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 电极导线植入情况 -->
      <div class="electrode-lead-implantation">
        <div class="electrode-lead-implantation-head">
          <span class="inner-title">电极导线植入情况</span>
          <!-- <el-tag type="info"
                style="font-size: 14px; color: #000">电极导线植入情况</el-tag> -->
        </div>
        <div class="electrode-lead-implantation-body">
          <el-tabs type="border-card">
            <!-- 右室电极 -->
            <el-tab-pane label="右室电极">
              <div class="input-form">
                <div class="input-form-item">
                  <div class="input-form-item-label">植入部位</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_RVImplaSite"
                    :value.sync="pileWireSituationInfo.implanSiteL1"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">起搏阈值</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.thresholdValueL1"
                      size="mini"
                      unit="V"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">脉宽</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.pulseWidthL1"
                      size="mini"
                      unit="ms"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">感知</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.perceptionValueL1"
                      size="mini"
                      unit="mV"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">阻抗</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.impedanceL1"
                      size="mini"
                      unit="Ω"
                    />
                  </div>
                </div>
              </div>
            </el-tab-pane>

            <!-- 右房电极 -->
            <el-tab-pane label="右房电极">
              <div class="input-form">
                <div class="input-form-item">
                  <div class="input-form-item-label">植入部位</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_RAImplaSite"
                    :value.sync="pileWireSituationInfo.implanSiteL2"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">起搏阈值</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.thresholdValueL2"
                      size="mini"
                      unit="V"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">脉宽</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.pulseWidthL2"
                      size="mini"
                      unit="ms"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">感知</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.perceptionValueL2"
                      size="mini"
                      unit="mV"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">阻抗</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.impedanceL2"
                      size="mini"
                      unit="Ω"
                    />
                  </div>
                </div>
              </div>
            </el-tab-pane>

            <!-- 左室电极 -->
            <el-tab-pane label="左室电极">
              <div class="input-form">
                <div class="input-form-item">
                  <div class="input-form-item-label">冠状窦导引鞘管</div>
                  <!-- <cqt-select :isReadonly="isReadOnly"
                            activeUrl="/v1/webconsole/comboData/get/PM_ImplaSite"
                            :value.sync="pileWireSituationInfo.guideSheathL3" /> -->
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="text"
                      v-model="pileWireSituationInfo.guideSheathL3"
                      size="mini"
                    />
                  </div>
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label">冠状窦左室电极植入是否成功</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_ImplaSucceed"
                    :value.sync="pileWireSituationInfo.implaSucceedL3"
                  />
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label">植入部位</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/PM_ImplaSite"
                    :value.sync="pileWireSituationInfo.implanSiteL3"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">起搏阈值</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.thresholdValueL3"
                      size="mini"
                      unit="V"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">脉宽</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.pulseWidthL3"
                      size="mini"
                      unit="ms"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">感知</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.perceptionValueL3"
                      size="mini"
                      unit="mV"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">阻抗</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="pileWireSituationInfo.impedanceL3"
                      size="mini"
                      unit="Ω"
                    />
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <!-- 起搏器类型 ICD -->
      <div class="ICD" v-show="ICDShow">
        <div class="ICD-content">
          <div class="ICD-head">
            <span class="inner-title">ICD</span>
          </div>
          <div class="ICD-body">
            <el-tabs type="border-card">
              <el-tab-pane label="ICD高压阻抗测试">
                <div class="input-form">
                  <div class="input-form-item">
                    <div class="input-form-item-label">右室</div>
                    <div>
                      <cqt-input
                        :isReadonly="isReadOnly"
                        class="input_has_unit"
                        placeholder="请输入"
                        type="number"
                        v-model="PMICDRecordInfo.rVentricleTestValue"
                        size="mini"
                        unit="V"
                      />
                    </div>
                  </div>

                  <div class="input-form-item">
                    <div class="input-form-item-label">上腔静脉</div>
                    <div>
                      <cqt-input
                        :isReadonly="isReadOnly"
                        class="input_has_unit"
                        placeholder="请输入"
                        type="number"
                        v-model="PMICDRecordInfo.superiorVenaCavaValue"
                        size="mini"
                        unit="V"
                      />
                    </div>
                  </div>
                  <div class="input-form-item">
                    <div class="input-form-item-label">ATP治疗</div>
                    <cqt-select
                      :isReadonly="isReadOnly"
                      activeUrl="/v1/webconsole/comboData/get/PM_ATPTreat"
                      :value.sync="PMICDRecordInfo.aTPTreat"
                    />
                  </div>

                  <div class="input-form-item">
                    <div class="input-form-item-label">除颤治疗</div>
                    <cqt-select
                      :isReadonly="isReadOnly"
                      activeUrl="/v1/webconsole/comboData/get/PM_Defibri"
                      :value.sync="PMICDRecordInfo.defibri"
                    />
                  </div>
                </div>
              </el-tab-pane>

              <el-tab-pane label="ICD除颤测试">
                <div class="input-form">
                  <div class="input-form-item">
                    <div class="input-form-item-label">除颤方式</div>
                    <cqt-select
                      :isReadonly="isReadOnly"
                      activeUrl="/v1/webconsole/comboData/get/PM_DefibriMode"
                      :value.sync="PMICDRecordInfo.defibrillationMode"
                    />
                  </div>

                  <div class="input-form-item">
                    <div class="input-form-item-label">ICD是否自动识别</div>
                    <cqt-select
                      :isReadonly="isReadOnly"
                      activeUrl="/v1/webconsole/comboData/get/PM_ICDAutoDistin"
                      :value.sync="PMICDRecordInfo.iCDAutoDistinguish"
                    />
                  </div>

                  <div class="input-form-item">
                    <div class="input-form-item-label">除颤能量</div>
                    <div>
                      <cqt-input
                        :isReadonly="isReadOnly"
                        class="input_has_unit"
                        placeholder="请输入"
                        type="number"
                        v-model="PMICDRecordInfo.defibrillationEnergy"
                        size="mini"
                        unit="J"
                      />
                    </div>
                  </div>

                  <div class="input-form-item">
                    <div class="input-form-item-label">除颤次数</div>
                    <div>
                      <cqt-input
                        :isReadonly="isReadOnly"
                        class="input_has_unit"
                        placeholder="请输入"
                        type="number"
                        v-model="PMICDRecordInfo.defibrillationTimes"
                        size="mini"
                      />
                    </div>
                  </div>

                  <div class="input-form-item">
                    <div class="input-form-item-label">是否转为窦性心律</div>
                    <cqt-select
                      :isReadonly="isReadOnly"
                      activeUrl="/v1/webconsole/comboData/get/PM_BeNormal"
                      :value.sync="PMICDRecordInfo.beNormal"
                    />
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

      <!-- 起搏器类型 CRT -->
      <div class="CRT" v-show="CRTShow">
        <div class="CRT-content">
          <div class="CRT-head">
            <span class="inner-title">CRT-D/DRT-P</span>
          </div>
          <div class="CRT-body">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">易损性上限：</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="PMICDRecordInfo.supVulnerable"
                    size="mini"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">最低除颤成功能量：</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="PMICDRecordInfo.minDefibriEnergy"
                    size="mini"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 并发症 -->
      <ComplicationForm
        :isReadonly="isReadOnly"
        :complicationData="universalStudy"
        complicationUrl="/v1/webconsole/comboData/get/PM_Complication"
        finalDiagnosisUrl="/v1/webconsole/comboData/get/PM_post_diagnosis"
        ref="ComplicationForm"
      />

      <!-- 手术经过 -->
      <div class="surgery-procedure">
        <surgery-procedure
          :isReadOnly="isReadOnly"
          :templateData="templateProcedureData"
          :surgeryInfo="universalStudy.studyProcess"
          :operation="operation"
          ref="SurgeryProcedure"
          @infoPreview="infoPreview"
        />
      </div>

      <!-- 手术结果 -->
      <!-- <div class="surgery-result">
        <surgery-result
          :isReadOnly="isReadOnly"
          :templateData="templateResultData"
          :surgeryInfo="universalStudy.studyResult"
          :operation="operation"
          ref="SurgeryResult"
        />
      </div> -->

      <!-- 术后诊疗计划 -->
      <div class="post-operative-medical-diagnosis">
        <post-medical-diagnosis
          :isReadOnly="isReadOnly"
          :templateData="templateDiagnosisData"
          :surgeryInfo="universalStudy"
          :operation="operation"
          ref="PostMedicalDiagnosis"
        />
      </div>

      <!-- 签名 -->
      <sign-report :operation="operation" @signComplete="signComplete" ref="SignReport" />

      <!-- pdf -->
      <el-dialog title="报告详情" :visible.sync="dialogVisible" width="70%" :modal="false">
        <div>
          <iframe :src="this.report_address" style="height: 890px; width: 100%"></iframe>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SurgeryProcedure from '../components/SurgeryProcedure.vue'
import SurgeryResult from '../components/SurgeryResult.vue'
import ComplicationForm from '../components/ComplicationForm.vue'
import PostMedicalDiagnosis from '../components/PostMedicalDiagnosis.vue'

import SignReport from '../components/SignReport.vue'

// cqt
import CqtInput from '../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../components/cqt/CqtRadio.vue'

import { PDF_HOST } from '../../../utils/globalVariable'

import { differMin } from '../../../utils'

import BloodCloseType from '../components/BloodCloseType.vue'
import Transfusion from '../components/Transfusion.vue'

export default {
  components: {
    SurgeryProcedure,
    SurgeryResult,
    PostMedicalDiagnosis,
    SignReport,
    CqtInput,
    CqtSelect,
    CqtRadio,
    ComplicationForm,
    BloodCloseType,
    Transfusion
  },
  data() {
    return {
      loading: true,
      report_address: '',
      dialogVisible: false,
      eportInfo: null,
      signStatus: false,
      templateAdviceData: {
        templateName: '术后医嘱'
      },
      templateProcedureData: {
        templateName: '手术经过'
      },
      templateResultData: {
        templateName: '手术结果'
      },
      templateDiagnosisData: {
        templateName: '术后诊疗计划'
      },
      radio: '新增起搏器',
      isShow: false,
      tempPaceEleShow: false,
      ICDShow: false,
      CRTShow: false,
      pacemakerIndex: -1,
      TypeInfo: '',
      pacemakerInfo: {
        assistantDoctor1: null,
        assistantDoctor2: null,
        brakeParts: null,
        canTearSheathSize: null,
        chiefSurgeon: null,
        complication: null,
        discomfort: null,
        examineDoctor: null,
        frequencyRespone: null,
        guideWireNumber: null,
        incisionLength: 0,
        operateType: '新增起搏器',
        pacemakerIndex: null,
        pacemakerType: null,
        postAdvice: null,
        pouchLoc: null,
        pouchSize: null,
        pressureTime: 0,
        puncture: null,
        remoteTest: null,
        replaceReason: null,
        studyRecord: null,
        // studyResult: null,
        workCorrectly: null
      },
      // 更换起搏器数据
      PMReplaceRecordInfo: {
        abnormalityL1: null,
        abnormalityL2: null,
        abnormalityL3: null,
        defibriAbnormalityL1: null,
        defibriAbnormalityL2: null,
        defibriAbnormalityL3: null,
        impedanceL1: 0,
        impedanceL2: 0,
        impedanceL3: 0,
        incisionLength: 0,
        incisionSite: null,
        leftVentricleL3: null,
        pacemakerFuncL1: null,
        pacemakerFuncL2: null,
        pacemakerFuncL3: null,
        pacemakerIndex: null,
        pacingThresholdValueL1: 0,
        pacingThresholdValueL2: 0,
        pacingThresholdValueL3: 0,
        perceptionValueL1: 0,
        perceptionValueL2: 0,
        perceptionValueL3: 0,
        placeProblemL1: null,
        placeProblemL2: null,
        placeProblemL3: null,
        pulseWidthL1: 0,
        pulseWidthL2: 0,
        pulseWidthL3: 0,
        replaceRecordIndex: '',
        rightAtriumL1: null,
        rightVentricleL2: null,
        wireCompleteL1: null,
        wireCompleteL2: null,
        wireCompleteL3: null
      },
      pileWireSituationInfo: {
        elecPileIndex: '',
        failedReasonL3: '',
        guideSheathL3: '',
        impedanceL1: 0,
        impedanceL2: 0,
        impedanceL3: 0,
        implaSucceedL3: '',
        implanSiteL1: '',
        implanSiteL2: '',
        implanSiteL3: '',
        pacemakerIndex: null,
        perceptionValueL1: 0,
        perceptionValueL2: 0,
        perceptionValueL3: 0,
        pulseWidthL1: 0,
        pulseWidthL2: 0,
        pulseWidthL3: 0,
        thresholdValueL1: 0,
        thresholdValueL2: 0,
        thresholdValueL3: 0
      },
      PMICDRecordInfo: {
        aTPTreat: null,
        beNormal: null,
        defibri: null,
        defibrillationEnergy: 0,
        defibrillationMode: null,
        defibrillationTimes: 0,
        iCDAutoDistinguish: null,
        minDefibriEnergy: null,
        pMICDRecordIndex: '',
        pacemakerIndex: null,
        rVentricleTestValue: 0,
        supVulnerable: null,
        superiorVenaCavaValue: 0
      },
      isReadOnly: false,
      operationName: '起搏器',
      operation: 'Pacemaker',
      allInfo: {},
      initObj: {},
      examineTypeValue: '',

      formList: [],
      // 通用表单
      formData: {
        formId: '',
        id: '',
        items: []
      },
      allDetail: {},
      allInitInfo: {}, // 初始化值
      menuListData: null,
      universalStudy: {
        // 公共字段
        postDiagnosis: undefined, //术后诊断
        studyProcess: undefined, //手术经过
        // studyResult: undefined, //手术结果
        o2: undefined, //吸氧方式
        anesthesiaType: undefined, //麻醉方式
        vascularApproach: undefined, //血管入路
        otherApproachType: undefined, // 其他入路方式
        approachType: undefined, //入路方式
        superHardGW: undefined, //超硬导丝
        contrastAgent: undefined, //造影剂剂量(ml)
        rayQuantity: undefined, //射线量(mGy)
        exposureTime: undefined, //曝光时间(min)
        sheathSize: undefined, //鞘管大小
        sheathGoInTime: undefined, //鞘置入时间
        sheathOutTime: undefined, //鞘拔除时间
        sheathLength: undefined, //鞘置入时长 min
        isBleeding: '否', // 是否出血
        outTransAmount: undefined, //出血量
        transfusion: undefined, //是否输血
        transfusionComponent: undefined, //输血成分
        transfusionAmount: undefined, //输血量
        protamine: undefined, //鱼精蛋白
        protamineDose: undefined, //鱼精蛋白剂量（mg）
        vesslClosure: undefined, //血管闭合方式
        vesselObturator: undefined, //血管闭合器种类
        vesselObturatorOther: undefined, //血管闭合器种类其他
        intervention: undefined, //特殊操作
        pacingThreshold: undefined, //临时起搏阈值
        pacingFrequency: undefined, //临时起搏频率
        pacingOutput: undefined, //临时起搏输出
        pacingPerception: undefined, //临时起搏感知
        patientFeedback: undefined, //术后患者反映
        skinExamination: undefined, //术后皮肤检查
        vascularPulsation: undefined, //术后穿刺处血管搏动
        postOperativeBraking: undefined, //术后制动
        transferMethod: undefined, //转运方式
        postoperativeDestination: undefined, //术后去向
        riskAssessment: undefined, //风险评估
        complication: undefined, //并发症
        postAdvice: undefined, //术后医嘱
        postNotes: undefined, //术后注意事项
        treatmentPlanId: undefined, //术后诊疗计划id
        treatmentPlan: undefined, //术后诊疗,

        otherTransfusionComponent: undefined, //其他输血成分描述,
        wholeBloodVolume: undefined, //全血输血量,
        plasmaVolume: undefined, //血浆输血量,
        plateletVolume: undefined, //血小板输血量,
        redBloodCellVolume: undefined, //红细胞输血量,
        otherVolume: undefined, //其他成分血输血量,
        otherSuperHardGW: null, // 超硬导丝其他描述
        vesslClosureOther: undefined, // 血管闭合方式其他
        // postDiagnosisOther: undefined, // 术后诊断其他
        // complicationOther: undefined, // 并发症其他

        occluderType: undefined, // 封堵器类型
        occluderOtherType: undefined, // 封堵器类型其他
        staplerType: undefined, // 缝合器类型
        staplerOtherType: undefined, // 缝合器类型其他
        pressureTime: undefined, // 压迫时间
        temporaryPacing: undefined //临时起搏电极
      },
      defaultData: null,
      itemArr: [
        {
          // 电极导线植入情况
          elementId: 'PileWireSituation',
          value: null,
          extendTable: null
        },
        {
          // ICD高压阻抗测试数据
          elementId: 'PMICDRecord',
          value: null,
          extendTable: null
        },
        {
          // 起搏器更换记录
          elementId: 'PMReplaceRecord',
          value: null,
          extendTable: null
        }
      ],
      apiOptions: {
        vesslClosureUrl: 'PM_vessel_closure', // 血管闭合方式
        vesselObturatorUrl: 'PM_vessel_obturator', // 闭合器
        occluder_typeUrl: 'occluder_type', // 封堵器
        stapler_typeUrl: 'stapler_type', // 缝合器
        transfusionComponentUrl: 'AO_composition_blood_transfusion' // 输血成分
      }
    }
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.getMenuIndex()
        }
      },
      immediate: true
    },
    'pacemakerInfo.pacemakerType': {
      handler(val) {
        if (val) {
          this.examineTypeValue = val
          this.initAll()
          if (val.includes('ICD')) {
            this.ICDShow = true
          } else {
            this.ICDShow = false
          }
          if (val.includes('CRT')) {
            this.CRTShow = true
          } else {
            this.CRTShow = false
          }
        }
      }
      // immediate: true,
      // deep: true
    },
    'universalStudy.temporaryPacing': {
      handler(val) {
        if (val === '有') {
          this.tempPaceEleShow = true
        } else {
          this.tempPaceEleShow = false
          this.universalStudy.pacingThreshold = ''
          this.universalStudy.pacingFrequency = ''
          this.universalStudy.pacingOutput = ''
          this.universalStudy.pacingPerception = ''
        }
      }
    },
    'pacemakerInfo.operateType': {
      handler(val) {
        if (val == '更换起搏器') {
          this.pacemakerInfo.operateType = val
          this.isShow = true
          this.initAll()
        } else {
          this.pacemakerInfo.operateType = val
          this.isShow = false
        }
      }
    }
  },
  methods: {
    getrayQuantity() {
      if (this.universalStudy.rayQuantity) {
        if (this.universalStudy.rayQuantity > 500) this.universalStudy.rayQuantity = 500

        if (this.universalStudy.rayQuantity < 0) this.universalStudy.rayQuantity = 0
      }
    },
    getoutTransAmount() {
      if (this.universalStudy.outTransAmount) {
        if (this.universalStudy.outTransAmount > 5000) this.universalStudy.outTransAmount = 5000

        if (this.universalStudy.outTransAmount < 0) this.universalStudy.outTransAmount = 0
      }
    },
    gettransfusionAmount() {
      if (this.universalStudy.transfusionAmount) {
        if (this.universalStudy.transfusionAmount > 5000)
          this.universalStudy.transfusionAmount = 5000

        if (this.universalStudy.transfusionAmount < 0) this.universalStudy.transfusionAmount = 0
      }
    },
    getprotamineDose() {
      if (this.universalStudy.protamineDose) {
        if (this.universalStudy.protamineDose > 500) this.universalStudy.protamineDose = 500

        if (this.universalStudy.protamineDose < 0) this.universalStudy.protamineDose = 0
      }
    },
    getcontrastAgent() {
      if (this.universalStudy.contrastAgent) {
        if (this.universalStudy.contrastAgent > 300) this.universalStudy.contrastAgent = 300

        if (this.universalStudy.contrastAgent < 0) this.universalStudy.contrastAgent = 0
      }
    },
    differMin,
    // 1
    getMenuIndex() {
      if (this.$store.state.menuList.length > 0 && !this.$route.path.includes('follow')) {
        for (let i = 0; i < this.$store.state.menuList.length; i++) {
          const element = this.$store.state.menuList[i]
          if (element.menuCode.includes(this.$route.path)) {
            this.menuListData = element
          }
        }
        this.loading = true
        this.getTable()
      } else {
        let url = `/v1/webconsole/study/operation/menu/${this.info.uuid}`
        this.$api.get(url).then((res) => {
          let menuList = []
          if (res.data && res.data.data) {
            menuList = res.data.data.map((item) => {
              return {
                ...item,
                menuCode: `/Detail/${item.menuCode}`,
                code: item.menuCode
              }
            })
            for (let i = 0; i < menuList.length; i++) {
              const element = menuList[i]
              if (
                element.menuCode.includes(
                  JSON.parse(localStorage.getItem('followPath')).replace('/follow/info', '')
                )
              ) {
                this.menuListData = element
              }
            }
            this.getTable()
          }
        })
      }
    },
    // 2 获取表单id
    getTable() {
      let url = `/v1/webconsole/eform/universal/form/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api.get(url).then(({ data: res }) => {
        if (res && res.data) {
          this.universalForm = res.data
          this.formData.formId = res.data[0].formId
          let formUrl = `/v1/webconsole/eform/universal/formdata/${this.info.uuid}/${this.menuListData.moduleId}`
          let data = {
            formId: this.formData.formId
          }
          this.$api
            .get(formUrl, data)
            .then(({ data: res }) => {
              if (res && res.data) {
                if (res.data.length !== 0) {
                  this.formList = res.data
                  this.formData.id = res.data[0].id
                  this.getTableData()
                } else {
                  // 如果没有数据则使用初始化
                  this.getDefaultInfo()
                }
                this.getReportInfo() // 查询报告状态
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    // 3  查询基本数据（公共） 获取表单数据（私有）
    getTableData() {
      let url = `/v1/webconsole/eform/fields/${this.formList[0].formId}/${this.formList[0].id}`
      this.$api.get(url).then(({ data: res }) => {
        let obj = {}
        if (res && res.data) {
          for (let i in res.data) {
            if (!this.universalStudy?.hasOwnProperty(i)) {
              obj[i] = res.data[i].valueString
            }
            if (res.data[i].dataType === 'dataTable') {
              res.data[i].extendTable = []
            }
          }
          this.pacemakerInfo = { ...this.pacemakerInfo, ...obj }
          this.allDetail = res.data
          this._getPileWireSituation() // 电极导线植入情况
          this._getPMICDRecordInfo() // ICD高压阻抗测试数据
          this._getPMReplaceRecordInfo() // 起搏器更换记录
        }
      })

      // 查询基本数据（公共）
      this.$api
        .get(
          `/v1/webconsole/study/operation/universal/${this.info.uuid}?moduleCode=${this.menuListData.code}`
        )
        .then(({ data: res }) => {
          if (res && res.data) {
            let obj = {}
            for (let i in res.data) {
              if (this.universalStudy?.hasOwnProperty(i)) {
                obj[i] = res.data[i]
              }
            }
            this.universalStudy = { ...this.universalStudy, ...obj }
          }
        })
    },
    // 初始化数据
    getDefaultInfo() {
      if (this.info) {
        this.$api
          .get(
            `/v1/webconsole/eform/universal/init/${this.info.uuid}/${this.menuListData.moduleId}`
          )
          .then((res) => {
            if (res.data && res.data.data) {
              console.log('init', res)
              let obj = {}
              res.data.data.forEach((item) => {
                obj[item.fieldTitle] = item.initValue
                if (this.pacemakerInfo?.hasOwnProperty(item.fieldTitle)) {
                  // 基本信息
                  this.pacemakerInfo[item.fieldTitle] = item.initValue
                }
                if (this.universalStudy?.hasOwnProperty(item.fieldTitle)) {
                  // 公共字段
                  this.universalStudy[item.fieldTitle] = item.initValue
                }
                if (this.PMICDRecordInfo?.hasOwnProperty(item.fieldTitle)) {
                  this.PMICDRecordInfo[item.fieldTitle] = item.initValue
                }
                if (this.PMReplaceRecordInfo?.hasOwnProperty(item.fieldTitle)) {
                  this.PMReplaceRecordInfo[item.fieldTitle] = item.initValue
                }
                if (this.pileWireSituationInfo?.hasOwnProperty(item.fieldTitle)) {
                  // 电极导线植入情况
                  this.pileWireSituationInfo[item.fieldTitle] = item.initValue
                }
              })
              this.defaultData = { ...obj }
            }
          })
      }
    },
    getReportInfo() {
      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.reportInfo = res.data.data
            if (res.data.data?.signed === 'Y') {
              this.signStatus = true
            }
          }
        })
    },
    // 签名
    reportSign() {
      // 解签
      if (this.signStatus) {
        this.$refs.SignReport.showRelievePwdDialog()
      } else {
        this._save(() => {
          this.$refs.SignReport.getReportSignType()
        })
      }
    },
    signComplete(val) {
      this.signStatus = val
    },
    // 报告
    infoPreview() {
      const pdfLoading = this.$loading({
        lock: true,
        text: '报告加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      this.report_address = null

      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then(
          (res) => {
            pdfLoading.close()
            if (!res.data.data || res.data.data?.signed === 'N') {
              // 生成手术报告单
              this._save(() => {
                this.$api
                  .get(
                    `/v1/webconsole/report/save/doctor/${this.info.uuid}/${this.menuListData.code}`
                  )
                  .then(
                    (response) => {
                      pdfLoading.close()
                      if (response.data && response.data.data) {
                        if (!response.data.data.pdfFileName) {
                          return this.$message.info('暂无报告')
                        }
                        var s = encodeURI(response.data.data.pdfFileName)
                        if (response.data.data.pdfFileName.includes('http')) {
                          this.report_address = s
                        } else {
                          this.report_address =
                            PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
                        }
                        this.dialogVisible = true
                      } else {
                        return this.$message.info('暂无报告')
                      }
                    },
                    () => {
                      pdfLoading.close()
                      return this.$message.error('生成报告出错！')
                    }
                  )
                  .catch((err) => {
                    pdfLoading.close()
                    return this.$message.error('生成报告出错！')
                  })
              })
            } else {
              var s = encodeURIComponent(res.data.data.pdfFileName)
              if (res.data.data.pdfFileName.includes('http')) {
                this.report_address = s
              } else {
                this.report_address = PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
              }
              this.dialogVisible = true
            }
          },
          () => {
            pdfLoading.close()
            return this.$message.error('生成报告出错！')
          }
        )
    },
    // 电极导线植入情况
    _getPileWireSituation() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.PileWireSituation.id}`
      let formData = new FormData()
      formData.append('elementName', 'PileWireSituation')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          for (let item in res.data[0]) {
            if (this.pileWireSituationInfo.hasOwnProperty(item)) {
              this.pileWireSituationInfo[item] = res.data[0][item]
            }
          }
        }
      })
    },
    // ICD高压阻抗测试数据
    _getPMICDRecordInfo() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.PMICDRecord.id}`
      let formData = new FormData()
      formData.append('elementName', 'PMICDRecord')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          for (let item in res.data[0]) {
            if (this.PMICDRecordInfo.hasOwnProperty(item)) {
              this.PMICDRecordInfo[item] = res.data[0][item]
            }
          }
        }
      })
    },
    // 起搏器更换记录
    _getPMReplaceRecordInfo() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.PMReplaceRecord.id}`
      let formData = new FormData()
      formData.append('elementName', 'PMReplaceRecord')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          for (let item in res.data[0]) {
            if (this.PMReplaceRecordInfo.hasOwnProperty(item)) {
              this.PMReplaceRecordInfo[item] = res.data[0][item]
            }
          }
        }
      })
    },
    initAll() {
      if (this.examineTypeValue.includes('ICD')) {
        if (!this.PMICDRecordInfo.pMICDRecordIndex) {
          this.PMICDRecordInfo.aTPTreat = this.allInfo.PMICDRecordInfo?.aTPTreat
          this.PMICDRecordInfo.defibri = this.allInfo.PMICDRecordInfo?.defibri
          this.PMICDRecordInfo.defibrillationMode = this.allInfo.PMICDRecordInfo?.defibrillationMode
          this.PMICDRecordInfo.iCDAutoDistinguish = this.allInfo.PMICDRecordInfo?.iCDAutoDistinguish
          this.PMICDRecordInfo.beNormal = this.allInfo.PMICDRecordInfo?.beNormal
        }
      } else {
        this.PMICDRecordInfo.aTPTreat = null
        this.PMICDRecordInfo.defibri = null
        this.PMICDRecordInfo.defibrillationMode = null
        this.PMICDRecordInfo.iCDAutoDistinguish = null
        this.PMICDRecordInfo.beNormal = null
      }
      if (this.pacemakerInfo.operateType === '更换起搏器') {
        if (!this.PMReplaceRecordInfo.replaceRecordInde) {
          for (let j in this.allInfo.pmReplaceRecordInfo) {
            for (let k in this.PMReplaceRecordInfo) {
              if (k === j) {
                this.PMReplaceRecordInfo[k] = this.allInfo.pmReplaceRecordInfo[j]
              }
            }
          }
        }
      }
    },

    _clearCRT() {
      this.PMICDRecordInfo.supVulnerable = 0
      this.PMICDRecordInfo.minDefibriEnergy = 0
    },

    _clearICD1() {
      this.PMICDRecordInfo.rVentricleTestValue = 0
      this.PMICDRecordInfo.superiorVenaCavaValue = 0
    },

    _clearICD2() {
      this.PMICDRecordInfo.defibrillationMode = ''
      this.PMICDRecordInfo.iCDAutoDistinguish = ''
      this.PMICDRecordInfo.defibrillationEnergy = 0
      this.PMICDRecordInfo.defibrillationTimes = 0
      this.PMICDRecordInfo.beNormal = ''
    },
    _clearEle1() {
      this.pileWireSituationInfo.implanSiteL1 = ''
      this.pileWireSituationInfo.thresholdValueL1 = 0
      this.pileWireSituationInfo.perceptionValueL1 = 0
      this.pileWireSituationInfo.pulseWidthL1 = 0
      this.pileWireSituationInfo.impedanceL1 = 0
    },
    _clearEle2() {
      this.pileWireSituationInfo.implanSiteL2 = ''
      this.pileWireSituationInfo.thresholdValueL2 = 0
      this.pileWireSituationInfo.pulseWidthL2 = 0
      this.pileWireSituationInfo.perceptionValueL2 = 0
      this.pileWireSituationInfo.impedanceL2 = 0
    },

    _clearEle3() {
      this.pileWireSituationInfo.guideSheathL3 = ''
      this.pileWireSituationInfo.implaSucceedL3 = ''
      this.pileWireSituationInfo.implanSiteL3 = ''
      this.pileWireSituationInfo.thresholdValueL3 = 0
      this.pileWireSituationInfo.pulseWidthL3 = 0
      this.pileWireSituationInfo.perceptionValueL3 = 0
      this.pileWireSituationInfo.impedanceL3 = 0
    },

    _clearTempEle() {
      this.universalStudy.pacingThreshold = ''
      this.universalStudy.pacingFrequency = ''
      this.universalStudy.pacingOutput = ''
      this.universalStudy.pacingPerception = ''
    },

    _clearChangeRecordL1() {
      this.PMReplaceRecordInfo.impedanceL1 = 0
      this.PMReplaceRecordInfo.perceptionValueL1 = 0
      this.PMReplaceRecordInfo.pulseWidthL1 = 0
      this.PMReplaceRecordInfo.pacingThresholdValueL1 = 0
      this.PMReplaceRecordInfo.defibriAbnormalityL1 = ''
      this.PMReplaceRecordInfo.wireCompleteL1 = ''
      this.PMReplaceRecordInfo.placeProblemL1 = ''
      this.PMReplaceRecordInfo.abnormalityL1 = ''
      this.PMReplaceRecordInfo.rightAtriumL1 = ''
      this.PMReplaceRecordInfo.pacemakerFuncL1 = ''
    },

    _clearChangeRecordL2() {
      this.PMReplaceRecordInfo.impedanceL2 = 0
      this.PMReplaceRecordInfo.perceptionValueL2 = 0
      this.PMReplaceRecordInfo.pulseWidthL2 = 0
      this.PMReplaceRecordInfo.pacingThresholdValueL2 = 0
      this.PMReplaceRecordInfo.defibriAbnormalityL2 = ''
      this.PMReplaceRecordInfo.wireCompleteL2 = ''
      this.PMReplaceRecordInfo.placeProblemL2 = ''
      this.PMReplaceRecordInfo.abnormalityL2 = ''
      this.PMReplaceRecordInfo.rightVentricleL2 = ''
      this.PMReplaceRecordInfo.pacemakerFuncL2 = ''
    },

    _clearChangeRecordL3() {
      this.PMReplaceRecordInfo.impedanceL3 = 0
      this.PMReplaceRecordInfo.perceptionValueL3 = 0
      this.PMReplaceRecordInfo.pulseWidthL3 = 0
      this.PMReplaceRecordInfo.pacingThresholdValueL3 = 0
      this.PMReplaceRecordInfo.defibriAbnormalityL3 = ''
      this.PMReplaceRecordInfo.wireCompleteL3 = ''
      this.PMReplaceRecordInfo.placeProblemL3 = ''
      this.PMReplaceRecordInfo.abnormalityL3 = ''
      this.PMReplaceRecordInfo.leftVentricleL3 = ''
      this.PMReplaceRecordInfo.pacemakerFuncL3 = ''
    },

    open_save() {
      this.$confirm('是否保存起搏器信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._save()
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消保持'
          })
        })
    },
    _save(callback) {
      if (this.pacemakerInfo.pacemakerType.includes('ICD')) {
        this.PMICDRecordInfo.supVulnerable = 0
        this.PMICDRecordInfo.minDefibriEnergy = 0
      }
      if (this.pacemakerInfo.pacemakerType.includes('CRT')) {
        this.PMICDRecordInfo.defibrillationMode = ''
        this.PMICDRecordInfo.iCDAutoDistinguish = ''
        this.PMICDRecordInfo.defibrillationEnergy = 0
        this.PMICDRecordInfo.defibrillationTimes = 0
        this.PMICDRecordInfo.rVentricleTestValue = 0
        this.PMICDRecordInfo.superiorVenaCavaValue = 0
      }

      this.formData.items = [] // 重置item
      this.universalStudy = {
        ...this.universalStudy,
        ...this.$refs.BloodCloseType.aorticInfo,
        ...this.$refs.Transfusion.aorticInfo
      }
      let basicAorticInfo = this.pacemakerInfo // 全部基础信息
      let complicationForm = this.$refs.ComplicationForm.aorticInfo
      complicationForm = {
        ...complicationForm,
        complication: complicationForm.complication?.join('|'),
        postDiagnosis: complicationForm.postDiagnosis || ''
      } // 并发症
      this.loading = true
      let differentFields = {}

      for (let item in basicAorticInfo) {
        // 组装私有字段1
        if (!this.universalStudy?.hasOwnProperty(item)) {
          differentFields[item] = basicAorticInfo[item]
        }
      }

      // ----- 私有字段items进行数据组装2 ----
      for (let i in differentFields) {
        this.formData.items.push({
          elementId: i,
          value: differentFields[i],
          extendTable: null
        })
      }

      this.formData.universalStudy = {
        // 基础字段
        ...this.universalStudy,
        ...complicationForm,
        moduleId: this.menuListData.moduleId,
        operationId: this.info.uuid,
        treatmentPlanId: this.$refs.PostMedicalDiagnosis.treatmentPlanId, // 术后诊疗计划
        treatmentPlan: this.$refs.PostMedicalDiagnosis.treatmentPlan, // 术后诊疗计划
        studyProcess: this.$refs.SurgeryProcedure.studyRecord // 手术经过
        // studyResult: this.$refs.SurgeryResult.studyResult // 手术结果
      } // universalStudy字段
      this.handleData()
      let url = `/v1/webconsole/eform/universal/save/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api
        .post(url, this.formData)
        .then(
          ({ data: data }) => {
            if (data.status === 200) {
              this.formData.id = data.data.id
              this.loading = false
              this.$bus.$emit('saveFormInfo')
              !callback && this.$message.success('保存成功')
            }
          },
          () => {
            this.loading = false
            return !callback && this.$message.error('保存出错')
          }
        )
        .finally(() => {
          callback && callback()
        })
    },

    handleData() {
      let PMReplaceRecordArr = [] // 起搏器更换记录
      let pileWireSituationArr = [] // 电极导线植入情况
      let PMICDRecordArr = [] // ICD高压阻抗测试数据/CRT

      // 起搏器更换记录
      if (this.isShow) {
        PMReplaceRecordArr = Array.from(Array(1)).map((_, index) => {
          // 在这里进行 map 操作
          return this.convertElement(this.PMReplaceRecordInfo)
        })
      }

      // 电极导线植入情况
      pileWireSituationArr = Array.from(Array(1)).map((_, index) => {
        // 在这里进行 map 操作
        return this.convertElement(this.pileWireSituationInfo)
      })

      // ICD高压阻抗测试数据/CRT
      if (this.CRTShow || this.ICDShow) {
        PMICDRecordArr = Array.from(Array(1)).map((_, index) => {
          // 在这里进行 map 操作
          return this.convertElement(this.PMICDRecordInfo)
        })
      }

      this.itemArr.forEach((item) => {
        if (item.elementId === 'PileWireSituation') {
          item.extendTable = pileWireSituationArr
        }
        if (item.elementId === 'PMICDRecord') {
          item.extendTable = PMICDRecordArr
        }
        if (item.elementId === 'PMReplaceRecord') {
          item.extendTable = PMReplaceRecordArr
        }
      })

      this.formData.items = [...this.formData.items, ...this.itemArr]
    },
    convertElement(element) {
      const arr = []
      delete element?.ids

      for (const i in element) {
        // 直接push即可
        arr.push({
          elementId: i,
          value: element[i],
          extendTable: null
        })
      }

      return arr
    },

    turnChangePacemakerOff(val) {
      if (val == '更换起搏器') {
        this.pacemakerInfo.operateType = val
        this.isShow = true
      } else {
        this.pacemakerInfo.operateType = val
        this.isShow = false
      }
    }
  }
}
</script>
